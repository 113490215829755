import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import Main from "@app/page/main";
import { Loader } from "@app/components/loading";

const Details = lazy(() => import("@app/page/details"));
const Page404 = lazy(() => import("@app/page/404"));

export const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:orderCode" element={<Main />} />
        <Route
          path="/details/:orderCode"
          element={
            <Suspense fallback={<Loader/>}>
              <Details />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader/>}>
              <Page404 />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
