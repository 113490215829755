import { MarkerF, OverlayView } from "@react-google-maps/api";
import IcPinDriver from '@app/assets/icon/ic_pin_driver_free.svg'

type GoogleDriverPinProps = {
  position: {
    lat: number;
    lng: number;
  },
};

export const GoogleDriverPin = ({ position }: GoogleDriverPinProps) => {
  return <MarkerF icon={{
    url: IcPinDriver,
    scaledSize:  new google.maps.Size(60,60)
  }} position={position}  title="dadaed"/>;

  // return (
  //   <>
  //     <OverlayView
  //       mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //       position={position}
  //     >
     
  //       <DriverPin avatarPhoto={avatarPhoto}/>

  //     </OverlayView>
  //   </>
  // );
};
