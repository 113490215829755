
// import { getSetting } from '@app/utils/project';
import { Helmet } from 'react-helmet'
import { RootState } from "@app/store";
// import { memo } from "react";
import { useSelector } from "react-redux";
type HelmetContainerProps = {};

export const HelmetContainer = ({}: HelmetContainerProps) => {


  const customerWebText = useSelector((state: RootState) => state.setting.customerWebText);
  const favIconUrl = useSelector((state: RootState) => state.setting.favIconUrl);
  const secondaryColor = useSelector((state: RootState) => state.setting.colors.secondaryColor);


  return (
    <>
    <Helmet>
        <title>{customerWebText.title}</title>
        <meta name={customerWebText.title} content='Track Page' />
        <link rel="icon" type="image/png" href={favIconUrl} sizes="16x16" />
        <meta name="theme-color" content={secondaryColor} />
    </Helmet>
    </>
    
  );
};
