import { RootState } from "@app/store";
import { setDropOffs, setPickup } from "@app/store/marker.slice";
import { setOrderDriver, setOrderStatus, setRealETA } from "@app/store/order.slice";
import { setService, setVehicle } from "@app/store/service.slice";
import {
  CustomerBusinessOrderUpdated,
  CustomerIndividualOrderUpdated,
} from "@app/types/socket";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import io, { Socket } from "socket.io-client";
import packageJson from "../../package.json";

type SocketIoInput = {};
export const useSocketIo = ({}: SocketIoInput) => {
  const socketServerUrl = useSelector(
    (state: RootState) => state.setting.socketServerUrl
  );
  const customerId = useSelector(
    (state: RootState) => state.customer.customerId
  );
  const language = useSelector((state: RootState) => state.customer.language);
  const orderId = useSelector((state: RootState) => state.order.orderId);

  console.log({ socketServerUrl, customerId, language });

  let socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  const [isConnected, setIsConnected] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (socketServerUrl && customerId && language) {
      socket = io(socketServerUrl, {
        secure: true,
        query: {
          customerId: customerId,
          language: language || "en",
          apiVersion: "v1",
          apiSource: "web",
          appVersionCode: packageJson.version,
          appVersionName: packageJson.version,
        },
        reconnection: true,
        reconnectionAttempts: 1000,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 4000,
        autoConnect: true,
        transports: ["websocket"],
      });
      socket.on("connect", () => {
        setIsConnected(true);
        console.log("Socket Connect");
      });

      socket.on("disconnect", () => {
        setIsConnected(false);
        console.log("Socket Disconnect");
      });

      socket.on("customer:business:order:updated",
        (data: CustomerBusinessOrderUpdated) => {
          console.log("customer:business:order:updated : ", data);
          if (data.data.id === orderId) {
            dispatch(
              setOrderStatus({
                status: data.data.status,
                statusDescription: data.data.statusDescription,
              })
            );

            if (data.data.driver) {
              dispatch(
                setOrderDriver({
                  avatarPhoto: data.data.driver.avatarPhoto,
                  name: data.data.driver.name,
                  phone: data.data.driver.phone,
                  rate: data.data.driver.rate,
                  vehicle: {
                    plate: data.data.driver.vehicle.plate,
                    color: data.data.driver.vehicle.color,
                    model: data.data.driver.vehicle.model,
                  },
                })
              );
            }

            if (data.data.service) {
              let options = [];
              options = data.data.service.options.map((o) => ({
                title: o.title,
                type: o.type,
                dataTitle: o.dataTitle,
              }));

              dispatch(
                setService({
                  title: data.data.service.title,
                  icon: data.data.service.icon,
                  options: options,
                })
              );
            }

            if (data.data.vehicleType) {
              let options = [];
              options = data.data.vehicleType.options.map((o) => ({
                title: o.title,
                type: o.type,
                dataTitle: o.dataTitle,
              }));

              dispatch(
                setVehicle({
                  title: data.data.vehicleType.title,
                  icon: data.data.vehicleType.icon,
                  options: options,
                })
              );
            }

            if (
              data.data.type == "Ondemand" &&
              data.data.pickup &&
              data.data.dropoffs
            ) {
              dispatch(
                setPickup({
                  lat: data.data.pickup.coordinates[1],
                  lng: data.data.pickup.coordinates[0],
                  address: data.data.pickup.address,
                })
              );
              const dropoffs = data.data.dropoffs.map((d: any) => {
                return {
                  lat: d.coordinates[1],
                  lng: d.coordinates[0],
                  address: d.address,
                };
              });
              dispatch(setDropOffs(dropoffs));
            }

            if (data.data.type == "Pickup" && data.data.pickup) {
              dispatch(
                setPickup({
                  lat: data.data.pickup.coordinates[1],
                  lng: data.data.pickup.coordinates[0],
                  address: data.data.pickup.address,
                })
              );
            }

            if (data.data.type == "Delivery" && data.data.dropoff) {
              dispatch(
                setDropOffs([
                  {
                    lat: data.data.dropoff.coordinates[1],
                    lng: data.data.dropoff.coordinates[0],
                    address: data.data.dropoff.address,
                  },
                ])
              );
            }

            if(data.data.realETA !== 0){
              dispatch(setRealETA(data.data.realETA));
            }
          }
        }
      );

      socket.on("customer:individual:order:updated",
        (data: CustomerIndividualOrderUpdated) => {
          console.log("customer:individual:order:updated : ", data);
          if (data.data.id === orderId) {
            dispatch(
              setOrderStatus({
                status: data.data.status,
                statusDescription: data.data.statusDescription,
              })
            );

            if (data.data.driver) {
              dispatch(
                setOrderDriver({
                  avatarPhoto: data.data.driver.avatarPhoto,
                  name: data.data.driver.name,
                  phone: data.data.driver.phone,
                  rate: data.data.driver.rate,
                  vehicle: {
                    plate: data.data.driver.vehicle.plate,
                    color: data.data.driver.vehicle.color,
                    model: data.data.driver.vehicle.model,
                  },
                })
              );
            }

            if (data.data.service) {
              let options = [];
              options = data.data.service.options.map((o) => ({
                title: o.title,
                type: o.type,
                dataTitle: o.dataTitle,
              }));

              dispatch(
                setService({
                  title: data.data.service.title,
                  icon: data.data.service.icon,
                  options: options,
                })
              );
            }

            if (data.data.vehicleType) {
              let options = [];
              options = data.data.vehicleType.options.map((o) => ({
                title: o.title,
                type: o.type,
                dataTitle: o.dataTitle,
              }));

              dispatch(
                setVehicle({
                  title: data.data.vehicleType.title,
                  icon: data.data.vehicleType.icon,
                  options: options,
                })
              );
            }

            if (
              data.data.type == "Ondemand" &&
              data.data.pickup &&
              data.data.dropoffs
            ) {
              dispatch(
                setPickup({
                  lat: data.data.pickup.coordinates[1],
                  lng: data.data.pickup.coordinates[0],
                  address: data.data.pickup.address,
                })
              );
              const dropoffs = data.data.dropoffs.map((d: any) => {
                return {
                  lat: d.coordinates[1],
                  lng: d.coordinates[0],
                  address: d.address,
                };
              });
              dispatch(setDropOffs(dropoffs));
            }

            if (data.data.type == "Pickup" && data.data.pickup) {
              dispatch(
                setPickup({
                  lat: data.data.pickup.coordinates[1],
                  lng: data.data.pickup.coordinates[0],
                  address: data.data.pickup.address,
                })
              );
            }

            if (data.data.type == "Delivery" && data.data.dropoff) {
              dispatch(
                setDropOffs([
                  {
                    lat: data.data.dropoff.coordinates[1],
                    lng: data.data.dropoff.coordinates[0],
                    address: data.data.dropoff.address,
                  },
                ])
              );
            }

            if(data.data.realETA !== 0){
              dispatch(setRealETA(data.data.realETA));
            }
          }
        }
      );
    }

    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("pong");
      }
    };
  }, [socketServerUrl, customerId, language]);

  console.log(isConnected);

  return { isConnected };
};
