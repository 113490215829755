import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ServiceState {
  service?: {
    title: string;
    icon: string;
    options: {
      title: string;
      type: "list" | "boolean";
      dataTitle: string | null;
    }[]
  },
  vehicle?: {
    title: string;
    icon: string;
    options: {
      title: string;
      type: "list" | "boolean";
      dataTitle: string | null;
    }[]
  }
}

const initialState: ServiceState = {};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setService: (state, action: PayloadAction<ServiceState['service']>) => {
      state.service = action.payload;
    },
    setVehicle: (state, action: PayloadAction<ServiceState['vehicle']>) => {
      state.vehicle = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setService , setVehicle } = serviceSlice.actions;

export default serviceSlice.reducer;
