import { RootState } from '@app/store';
import { useSelector } from 'react-redux';
import { StyledLink} from './callBtn.styles'
import { t } from '@app/utils/translation'

type CallBtnProps = {
  phone?: string,
  customStyles?:{
    marginLeft: string
  }
}

export const CallButton  =({ phone , customStyles } : CallBtnProps)=> {

  const status  = useSelector((state: RootState) => state.order.status );
  const language  = useSelector((state: RootState) => state.customer.language );


  if(status !== 'Done' && status !== 'SupportCanceled' && status !== 'DriverCanceled' && status !== 'CustomerCanceled'){
    if(phone){
      return <StyledLink style={customStyles} href={'tel:' + phone}> {t('CallDriver',language)} </StyledLink>
    }
  }
  return null

}