import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Languages } from "@app/types/multilingual";
import { CustomerType } from "@app/types/orders";

export interface CustomerState {
  customerId: string;
  name: string,
  type: CustomerType,
  language: Languages,
  avatarPhoto? : string
}

const initialState: CustomerState = {
  customerId: '',
  name: 'Customer',
  language: 'en',
  type: 'individual'
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<CustomerState>) => {
      state.customerId = action.payload.customerId;
      state.name = action.payload.name;
      state.type = action.payload.type;
      state.language = action.payload.language;
      state.avatarPhoto = action.payload.avatarPhoto;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCustomer } = customerSlice.actions;

export default customerSlice.reducer;
