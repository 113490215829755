

import styled from 'styled-components';

interface IFooter {
  height?: string,
}

const handleHeight = (height?: string) =>{
  if(height) return `height:${height}; top: calc(100% - ${height})`;
  else return `height:177px; top: calc(100% - 177px)`
}

export const Footer = styled.div<IFooter>`
  width: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  z-index: 2;
  padding: 0;
  border-start-start-radius: 10px;
  border-start-end-radius: 10px;
  
  ${props => handleHeight(props.height)};

`;


export const FooterWarper = styled.div`
  width: calc(100% - 32px);
  margin: 0px 16px 0 16px;
  float: left;

`;
