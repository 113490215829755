import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  CustomerWebColors,
  CustomerWebText,
  googleApplicationMapService,
  osmApplicationMapService,
} from "@app/types/setting";
import { settingFallback } from '@app/utils/constants'

export interface SettingState {
  map: googleApplicationMapService | osmApplicationMapService | null;
  restServerUrl: string,
  socketServerUrl: string,
  trackDriverTimer: number,
  logoTypeUrl: string,
  favIconUrl: string,
  colors: CustomerWebColors,
  customerWebText: CustomerWebText,
}

const initialState: SettingState = {
  map: null,
  restServerUrl: settingFallback.restServerUrl,
  socketServerUrl: settingFallback.socketServerUrl,
  trackDriverTimer: settingFallback.trackDriverTimer,
  logoTypeUrl: settingFallback.logoTypeUrl,
  favIconUrl: settingFallback.favIconUrl,
  colors: settingFallback.colors,
  customerWebText: settingFallback.customerWebText,
};


export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setMap: (state, action: PayloadAction<SettingState["map"]>) => {
      state.map = action.payload;
    },
    setRestServerUrl: (state, action: PayloadAction<SettingState["restServerUrl"]>) => {
      state.restServerUrl = action.payload;
    },
    setSocketServerUrl: (state, action: PayloadAction<SettingState["socketServerUrl"]>) => {
      state.socketServerUrl = action.payload;
    },
    setSetting: (state, action: PayloadAction<SettingState>) => {
      state.map = action.payload.map;
      state.restServerUrl = action.payload.restServerUrl;
      state.socketServerUrl = action.payload.socketServerUrl;
      state.trackDriverTimer = action.payload.trackDriverTimer;
      state.logoTypeUrl = action.payload.logoTypeUrl;
      state.favIconUrl = action.payload.favIconUrl;
      state.colors = action.payload.colors;
      state.customerWebText = action.payload.customerWebText;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMap , setRestServerUrl , setSocketServerUrl , setSetting } = settingSlice.actions;

export default settingSlice.reducer;
