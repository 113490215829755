import styled from 'styled-components';


export const CircleSpan = styled.span`
  margin: 13px 11px 0 0;
  background-color: #58a7ff;
  float: left;
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;

export const DetailsWarper = styled.div`
  width: auto;
  float: left;
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
  margin-top: 10px;
`;

export const StyledStatus = styled.span`
  font-family: SFProRounded;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111;
`;

export const StyledTime = styled.span`
  font-family: SFProRounded;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #acacac;
  float: right;
`;

export const StyledDescription = styled.span`
  font-family: SFProRounded;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin: 2px 0 6px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


export const EventWarper = styled.div`
  float: left;
  width: 100%;
  height: auto;
  min-height: 46px;
  position: relative;
`;

export const EventLine = styled.span`
  height: 40px;
  opacity: 0.1;
  background-color: #58a7ff;
  width: 1px;
  position: absolute;
  left: 3px;
  top: 22px;
`;
