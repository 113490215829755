interface LocalStorageService {
  get(key: string): string | null;
  set(key: string, value: string): void;
  remove(key: string): void;
  clear(): void;
}

export class LocalData implements LocalStorageService {
  get(key: string): string | null {
    return localStorage.getItem(String(key));
  }
  set(key: string, value: string): void {
    localStorage.setItem(String(key), value);
  }
  remove(key: string): void {
    localStorage.removeItem(String(key));
  }
  clear(): void {
    localStorage.clear();
  }
}
