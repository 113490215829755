import { RootState } from '@app/store';
import { useSelector } from 'react-redux';
import { Span} from './status.styles'

type ProfileProps = {
}

export const Status =({}: ProfileProps)=> {

  const status  = useSelector((state: RootState) =>  state.order.status );
  const statusDescription  = useSelector((state: RootState) =>  state.order.statusDescription );

  return (
        <Span status={status} > {statusDescription} </Span>
  );
}
