import { createGlobalStyle } from "styled-components";
import SFProRoundedBold from "./sf-pro/sf-pro-display_bold.woff2";
import SFProRoundedRegular from "./sf-pro/sf-pro-display_regular.woff2";

const FontStyles = createGlobalStyle`

@font-face {
    font-family: SFProRounded;
    font-style: normal;
    font-weight: bold;
    src: url(${SFProRoundedBold}) format('woff2');
  }
  
  @font-face {
    font-family: SFProRounded;
    font-style: normal;
    font-weight: normal;
    src: url(${SFProRoundedRegular}) format('woff2');
  }
 

`;

export default FontStyles;
