import "./App.css";
import { RouteManager } from "@app/route";
import { useSocketIo } from "@app/hooks";
import { HelmetContainer } from "./components/Helmet";
import { environment } from "./config/environment";

function App() {
  const { isConnected } = useSocketIo({});
  console.log({ isConnected });
  if(!environment.isDevelopment){
    console.log = ()=>{}
  }
  return (
    <>
      <HelmetContainer />
      <RouteManager />
    </>
  );
}

export default App;
