import styled from 'styled-components';


const handleColorByStatus = (status: string) =>{
  switch (status) {
    case "Pending":
      return "color: #25b7ff;";
    case "Unassigned":
      return "color: #ffad01;";
    case "Assigned":
      return "color: #1080ff;";
    case "Started":
      return "color: #004cc4;";
    case "PickedUp":
      return "color: #1080ff;";
    case "Done":
      return "color: #009b62;";
    case "CustomerCanceled":
      return "color: #484848;";
    case "DriverCanceled":
      return "color: #484848;";
    case "Arrived":
      return "color: #1080ff;";
    case "SupportCanceled":
      return "color: #484848;";
    case "Transit":
      return "color: #ffad01;";
    case "Delivering":
      return "color: #004cc4;";
    default:
      return "color: black";
  }
}

interface ISpan  {
  status: string,
}
export const Span = styled.span<ISpan>`
  width: calc(100% - 32px);
  margin: 12px 16px 0 16px;
  float: left;
  height: auto;
  border-bottom: solid 1px rgba(128, 128, 128, 0.2);
  padding: 0 0 11.5px 0;
  font-family: SFProRounded;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  
  ${props => handleColorByStatus(props.status)};
`;
