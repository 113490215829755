import styled from 'styled-components';

export const StyledWarper = styled.div`
  width: 100%; 
  position: fixed;
  top: calc(100% - 235px);
  display: flex;
  justify-content: center;
`;

export const StyledSpan = styled.span`
  padding: 8px 10px;
  box-shadow: 0 2px 4px 0  rgba(128, 128, 128, 0.2);
  background-color: #fff;
  font-family: SFProRounded;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  flex-grow: 0;
  border-radius: 8px;
  color: #242424;
`;
