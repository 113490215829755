import { useVisibilityState } from "@app/hooks";
import { RootState } from "@app/store";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledSpan, StyledWarper } from "./eta.styles";
import { t } from '@app/utils/translation'

type ETAProps = {};

export const ETA = ({}: ETAProps) => {
  const orderType = useSelector((state: RootState) => state.order.type);
  const status = useSelector((state: RootState) => state.order.status);
  const realETA = useSelector((state: RootState) => state.order.realETA);
  const language  = useSelector((state: RootState) => state.customer.language );
 

  const [etaMessage, setEtaMessage] = useState<string | undefined>(undefined);
  const userActive = useVisibilityState();

  console.log({ userActive });



  useEffect(() => {
    if (
      orderType === "Ondemand" &&
      (status === "Started" || status === "Arrived" || status === "PickedUp") &&
      userActive && 
      realETA != 0
    ) {

      let d = new Date(realETA); 
      let time = d.getHours().toString().padStart(2,'0') + ':' + d.getMinutes().toString().padStart(2,'0')

      if(status == 'PickedUp'){
        setEtaMessage(`${t('FinishAt',language)} ${time}`);
      }else{
        setEtaMessage(`${t('PickupAt',language)} ${time}`);
      }

    } else {
      setEtaMessage(undefined);
    }

    return () => setEtaMessage(undefined);
  }, [status, userActive , realETA]);


  if (etaMessage) {
    return (
      <StyledWarper>
        <StyledSpan>{etaMessage}</StyledSpan>
      </StyledWarper>
    );
  } else {
    return null;
  }
};

export const MemoETA = memo(ETA)