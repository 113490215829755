import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Point } from "@app/types/orders";

export interface MarkerState {
  pickup?: Point;
  dropoffs?: Point[];
  driver?: Point;
}

const initialState: MarkerState = {};

export const markerSlice = createSlice({
  name: "marker",
  initialState,
  reducers: {
    setPickup: (state, action: PayloadAction<MarkerState['pickup']>) => {
      state.pickup = action.payload
    },
    setDropOffs: (state, action: PayloadAction<MarkerState['dropoffs']>) => {
      state.dropoffs = action.payload
    },
    setDriver: (state, action: PayloadAction<MarkerState['driver']>) => {
      state.driver = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPickup , setDropOffs ,  setDriver } = markerSlice.actions;

export default markerSlice.reducer;
