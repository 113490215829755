import { useState, useEffect, useCallback } from 'react';

export const useVisibilityState = () => {
  const [visibilityState, setVisibilityState] = useState<boolean>(true);

  const handleVisibilityChange = useCallback(() => {

    setVisibilityState(document.visibilityState === 'visible'  ? true : false);
  }, [setVisibilityState]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange , {passive: true});
    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [handleVisibilityChange]);

  return visibilityState;
};
