import type { RootState } from "@app/store";
import { shallowEqual, useSelector } from "react-redux";
import { Google } from "./google";
import { Osm } from "./osm";

type MapContainerProps = {};

export const MapContainer = ({}: MapContainerProps) => {
  const mapConfig = useSelector((state: RootState) => state.setting.map );
  const pickup = useSelector((state: RootState) => state.marker.pickup , shallowEqual);
  const dropoffs = useSelector((state: RootState) => state.marker.dropoffs , shallowEqual);
  const driver = useSelector((state: RootState) => state.marker.driver , shallowEqual);

 
  
  const markers: {
    lat: number;
    lng: number;
    type: "Pickup" | "DropOff" | "Driver";
  }[] = [];

  if(pickup){
    markers.push({lat: pickup.lat , lng: pickup.lng, type: "Pickup" });
  }
  if(dropoffs && dropoffs.length > 0){
    dropoffs.map(d =>{
      markers.push({lat: d.lat, lng: d.lng, type: "DropOff"});
    })
  }
  if(driver){
    markers.push({lat: driver.lat, lng: driver.lng, type: "Driver" });
  }

  

  if (mapConfig) {
    if (mapConfig.type === "google") {
      return (
          <Google apiKey={mapConfig.apiKey} markers={markers}/> 
      );
    } else if (mapConfig.type === "osm") {
      return (
          <Osm markers={markers} />
      );
    } else {
      return <span> Map not Provides </span>;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <span
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {" "}
          Loading...{" "}
        </span>
        ;
      </div>
    );
  }
};
