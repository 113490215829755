import { Marker } from 'react-leaflet';
import {Icon} from 'leaflet';
import IcPickupPin from "@app/assets/icon/ic_pickup_pin.svg";

type OsmPickupPinProps = {
  position: {
    lat: number;
    lng: number;
  };
};

const iconPickup = new Icon({
  iconUrl: IcPickupPin,
  iconRetinaUrl: IcPickupPin,
  iconSize: [60,60]
});


export const OsmPickupPin = ({ position }: OsmPickupPinProps) => {

  return (
    <Marker position={position} icon={iconPickup} />
  )
};



