import { MapContainer } from "@app/components/Map/container";
import { useVisibilityState } from "@app/hooks";
import {
  BusinessOrder,
  IndividualOrder,
} from "@app/services/api";
import { RootState } from "@app/store";
import { setDriver } from "@app/store/marker.slice";
import { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

type MapSectionProps = {};

let interval: any;
export const MapSection = ({}: MapSectionProps) => {
  const dispatch = useDispatch();

  const customerId = useSelector(
    (state: RootState) => state.customer.customerId
  );
  const language = useSelector((state: RootState) => state.customer.language);
  const customerType = useSelector((state: RootState) => state.customer.type);

  const orderId = useSelector((state: RootState) => state.order.orderId);
  const restServerUrl = useSelector(
    (state: RootState) => state.setting.restServerUrl
  );
  const status = useSelector((state: RootState) => state.order.status);
  const trackDriverTimer = useSelector(
    (state: RootState) => state.setting.trackDriverTimer
  );

  const userActive = useVisibilityState();

  const updateDriverPinInterval = () => {
    getTrackDateAndUpdate();

    interval = setInterval(() => {
      console.log("Driver Pin Interval ", status);
      getTrackDateAndUpdate();
    }, trackDriverTimer);
  };

  const getTrackDateAndUpdate = () => {
    if (customerType === "individual") {
      new IndividualOrder().trackDriver({
        customerId: customerId,
        language: language,
        orderId: orderId,
        restUrl: restServerUrl,
      }).then((result) => {
        // console.log({result});
        dispatch(
          setDriver({
            lat: result.data.location[1],
            lng: result.data.location[0],
          })
        );
      });
    } else if (customerType === "business") {
      new BusinessOrder().trackDriver({
        customerId: customerId,
        language: language,
        orderId: orderId,
        restUrl: restServerUrl,
      }).then((result) => {
        // console.log({result});
        dispatch(
          setDriver({
            lat: result.data.location[1],
            lng: result.data.location[0],
          })
        );
      });
    }
  };

  useEffect(() => {
    if (
      (status == "Started" || status == "Arrived" || status == "PickedUp") &&
      userActive
    ) {
      console.log("updateDriverPinInterval call");
      updateDriverPinInterval();
    } else {
      clearInterval(interval);
      // dispatch(setDriver(undefined));
    }

    return () => clearInterval(interval);
  }, [status, userActive]);

  return <MapContainer />;
};

export const MemoMapSection = memo(MapSection)