import styled from 'styled-components';

interface IStyledWarper  {
  height?: string,
}

export const StyledWarper = styled.div<IStyledWarper>`
  width: calc(100% + 32px);
  height: ${props => props.height || "" };
  flex-grow: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  float: left;
  /* margin-top: -3px; */
  margin-bottom: 16px;
  margin-left: -16px;
  min-height: auto;
`;


export const StyledAvatar = styled.img`
  width: 58px;
  height: 58px;
  float: left;
  border-radius: 50%;
  margin: 16px 8px 0 16px;
  object-fit: cover;
`;


export const StyledDetailsWarper = styled.div`
  width: auto;
  float: left;
  margin: 27px 0 0 0;
  display: flex;
  flex-direction: column;
`;

interface ISpan  {
  fontSize?: string,
  fontWeight?: 'normal' | 'bold',
  color?: string,
  textAlign?: 'left' | 'center' ,
}
export const Span = styled.span<ISpan>`
  margin: 0 0 4px;
  font-family: SFProRounded;
  font-size: ${props => props.fontSize || "16px" };
  font-weight: ${props=> props.fontWeight || "normal"};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props=> props.textAlign || "left"};
  color: ${props => props.color || "#242424"};
`;


export const StyledVehicleTypeWarper = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2px 15px 0 0;
  float: right;
  align-items: center;
`;
