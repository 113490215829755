import React from 'react';
import { StyledWarper , StyledAvatar , StyledDetailsWarper , Span, StyledVehicleTypeWarper} from './driverProfile.styles'
import IcProfile from '@app/assets/icon/profile_active.png';
import IcStar from '@app/assets/icon/star.svg'

type DriverProfileProps = {
    avatarPhoto?: string,
    name: string,
    rate: number,
    vehicleTypeIcon?: string,
    vehiclePlate: string,
    vehicleColor: string,
    vehicleModel: string,
    height: string

}

export const DriverProfile =({avatarPhoto , name , rate , vehicleTypeIcon , vehiclePlate , vehicleColor , vehicleModel , height}: DriverProfileProps)=> {
  return (
    <StyledWarper height={height} style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
    }}>
        <div style={{width: '70%'}}>

         <StyledAvatar src={avatarPhoto ? avatarPhoto : IcProfile } alt="profile"/> 
         <StyledDetailsWarper style={{
            width: '64%'
         }} >
                <Span
                      style={{
                        whiteSpace: 'nowrap',
                        width: '100%',
                        overflow: 'overlay',
                        textOverflow: 'clip',
                  }}
                > {name}</Span>
                <Span color="#484848" fontSize="12px" > 
                  <img src={IcStar} alt="Star" />
                {rate} 
                </Span>
          </StyledDetailsWarper>

          </div>
          
          <StyledVehicleTypeWarper style={{width: '30%'}}>
            <img style={{ height: "45px", width: "50px", margin: "0 0 3.5px" }} src={vehicleTypeIcon} alt="Car" />
            <Span style={{marginBottom : '1px'}} fontSize="14px" fontWeight="bold" textAlign='center' > {vehiclePlate} </Span>
            <Span fontSize="14px" textAlign='center' color="#808080" style={{
                  whiteSpace: 'nowrap',
                  width: '60%',
                  overflow: 'overlay',
                  textOverflow: 'clip',
            }} > {vehicleColor} {vehicleModel} </Span>

          </StyledVehicleTypeWarper>
    </StyledWarper>
  );
}
