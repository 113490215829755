import { MarkerF } from "@react-google-maps/api";
import IcDropoffPin from "@app/assets/icon/ic_dropoff_pin.svg";

type GoogleDropOffPinProps = {
  position: {
    lat: number;
    lng: number;
  };
};

export const GoogleDropOffPin = ({ position }: GoogleDropOffPinProps) => {

  return <MarkerF icon={{
    url: IcDropoffPin,
    scaledSize:  new google.maps.Size(60,60)
  }} position={position} />;
};
