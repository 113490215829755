import { MarkerF, OverlayView } from "@react-google-maps/api";
import IcPickupPin from "@app/assets/icon/ic_pickup_pin.svg";

type GooglePickupPinProps = {
  position: {
    lat: number;
    lng: number;
  };
};

export const GooglePickupPin = ({ position }: GooglePickupPinProps) => {
  // return (
  //   <>
  //     <OverlayView
  //       mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //       key={Math.random()}
  //       position={position}
  //     >
     
  //         <PickupPinSvg />

  //     </OverlayView>
  //   </>
  // );

  return <MarkerF icon={{
    url: IcPickupPin,
    scaledSize:  new google.maps.Size(60,60)
  }} position={position} />;
};
