import { useEffect } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { OsmDriverPin } from "./driver-pin";
import { OsmDropOffPin } from "./dropoff-pin";
import { OsmPickupPin } from "./pickup-pin";
import { LatLngBoundsLiteral } from "leaflet";

const center = {
  lat: -3.745,
  lng: -38.523,
};

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 190px)",
  marginTop: "50px",
  zIndex:0
};

type OsmProps = {
  url?: string;
  markers?: {
    lat: number;
    lng: number;
    type: "Pickup" | "DropOff" | "Driver";
    avatarPhoto?: string;
  }[];
};

type OsmMapControllerProps = {
  markers?: {
    lat: number;
    lng: number;
    type: "Pickup" | "DropOff" | "Driver";
    avatarPhoto?: string;
  }[];
};

export const Osm = ({ url, markers }: OsmProps) => {
  let tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  if (url) tileUrl = url;

  return (
    <MapContainer
      center={center}
      zoom={13}
      maxZoom={18}
      minZoom={1}
      inertia={true}
      easeLinearity={0.25}
      zoomControl={true}
      scrollWheelZoom={true}
      style={containerStyle}
      boundsOptions={{
        paddingBottomRight: [150, 150],
        paddingTopLeft: [150, 150],
      }}
    >
      <TileLayer
        url={tileUrl}
      />
      <OsmMapController markers={markers} />

      {markers &&
        markers.map((marker) => {
          if (marker.type === "Pickup") {
            return (
              <OsmPickupPin
                key={`${marker.lat}${marker.lng}`}
                position={{
                  lat: marker.lat,
                  lng: marker.lng,
                }}
              />
            );
          } else if (marker.type === "DropOff") {
            return (
              <OsmDropOffPin
                key={`${marker.lat}${marker.lng}`}
                position={{
                  lat: marker.lat,
                  lng: marker.lng,
                }}
              />
            );
          } else if (marker.type === "Driver") {
            return (
              <OsmDriverPin
                key={`${marker.lat}${marker.lng}`}
                position={{
                  lat: marker.lat,
                  lng: marker.lng,
                }}
              />
            );
          }
        })}
    </MapContainer>
  );
};

const OsmMapController = ({ markers }: OsmMapControllerProps) => {
  const map = useMap();

  useEffect(() => {
    
    if (map && markers) {
      const latLngBounds : LatLngBoundsLiteral = [] ;

      markers.map((item) => {
        latLngBounds.push([item.lat, item.lng]);
      });
      
      if(markers.length == 1){
        latLngBounds.push([markers[0].lat + 0.002, markers[0].lng + 0.002]);
        latLngBounds.push([markers[0].lat - 0.002, markers[0].lng - 0.002]);
      }
      map.fitBounds(latLngBounds, { padding : [100 , 100]} );
    }
  }, [map, markers]);
  return <></>;
};
