import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  OrderType,
  OrderStatus,
  DriverType,
} from "@app/types/orders";

export interface OrderState {
  orderId: string;
  type: typeof OrderType[number];
  status: OrderStatus;
  statusDescription: string;
  driver?: DriverType;
  events: {
    title: string;
    description: string;
    createdAt: number;
  }[],
  isEventNeedUpdate: boolean;
  realETA: number;
}

const initialState: OrderState = {
  orderId: "",
  type: "Ondemand",
  status: "Assigned",
  statusDescription: "",
  events: [],
  isEventNeedUpdate: false,
  realETA: 0,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderId: (state, action: PayloadAction<OrderState["orderId"]>) => {
      state.orderId = action.payload;
    },
    setOrderType: (state, action: PayloadAction<OrderState["type"]>) => {
      state.type = action.payload;
    },
    setOrderStatus: (
      state,
      action: PayloadAction<Pick<OrderState, "status" | "statusDescription">>
    ) => {
      if((state.status != action.payload.status) && state.type !== 'Ondemand' ) state.isEventNeedUpdate = true;
      state.status = action.payload.status;
      state.statusDescription = action.payload.statusDescription;

    },
    setOrderDriver: (state, action: PayloadAction<DriverType>) => {
      if (!state.driver) state.driver = Object();
      if (state.driver) {
        state.driver.avatarPhoto = action.payload.avatarPhoto;
        state.driver.name = action.payload.name;
        state.driver.phone = action.payload.phone;
        state.driver.rate = action.payload.rate;
        state.driver.vehicle = action.payload.vehicle;
        state.driver.vehicle.plate = action.payload.vehicle.plate;
        state.driver.vehicle.model = action.payload.vehicle.model;
        state.driver.vehicle.color = action.payload.vehicle.color;
      }
    },
    setOrderEvents: (state, action: PayloadAction<OrderState["events"]>) => {
      state.events = action.payload;
    },
    addOrderEvent: (
      state,
      action: PayloadAction<OrderState["events"][number]>
    ) => {
      state.events.push(action.payload);
    },
    setEventNeedUpdate: (state, action: PayloadAction<OrderState["isEventNeedUpdate"]>) => {
      state.isEventNeedUpdate = action.payload;
    },
    setRealETA: (state, action: PayloadAction<OrderState["realETA"]>) => {
      state.realETA = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOrderType,
  setOrderStatus,
  setOrderDriver,
  setOrderId,
  setOrderEvents,
  addOrderEvent,
  setEventNeedUpdate,
  setRealETA
} = orderSlice.actions;

export default orderSlice.reducer;
