import { IndividualOrder , BusinessOrder } from "@app/services/api";
import { RootState } from "@app/store";
import { setEventNeedUpdate, setOrderEvents } from "@app/store/order.slice";
import { formatDateTime } from "@app/utils/date";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircleSpan,
  DetailsWarper,
  StyledStatus,
  StyledTime,
  StyledDescription,
  EventWarper,
  EventLine,
} from "./eventsBox.styles";

type EventBoxProps = {
  title: string;
  description: string;
  createdAt: number;
};

type EventsBoxProps = {
  events: { title: string; description: string; createdAt: number }[];
};

export const EventBox = ({ title, description, createdAt }: EventBoxProps) => {
  const time = formatDateTime(createdAt);

  return (
    <>
      <EventUpdater/>
      <CircleSpan />
      <DetailsWarper>
        <StyledStatus>
          {title}
          <StyledTime> {time} </StyledTime>
        </StyledStatus>
        <StyledDescription>{description}</StyledDescription>
      </DetailsWarper>
    </>
  );
};

export const EventsBox = ({ events }: EventsBoxProps) => {
  return (
    <>
    <EventUpdater/>
      {events.map((e , idx) => {
        const time = formatDateTime(e.createdAt);

        const isLastOne = events.length === idx + 1
        return (
          <EventWarper key={e.createdAt}>
            {isLastOne ? null : <EventLine />}
            <CircleSpan />
            <DetailsWarper>
              <StyledStatus>
                {e.title}
                <StyledTime> {time} </StyledTime>
              </StyledStatus>
              <StyledDescription>{e.description}</StyledDescription>
            </DetailsWarper>
          </EventWarper>
        );
      })}
    </>
  );
};

export const EventUpdater = () => {
  
  const isEventNeedUpdate = useSelector((state: RootState) => state.order.isEventNeedUpdate);
  const customerType = useSelector((state: RootState) => state.customer.type);
  const customerId = useSelector((state: RootState) => state.customer.customerId);
  const language = useSelector((state: RootState) => state.customer.language);
  const orderId = useSelector((state: RootState) => state.order.orderId);
  const restServerUrl = useSelector((state: RootState) => state.setting.restServerUrl);

  const dispatch = useDispatch();

  const updateEvents = ()=>{
    if(customerType == 'individual'){
      new IndividualOrder().getEvents({
        customerId: customerId,
        language: language,
        orderId: orderId,
        restUrl: restServerUrl
      }).then(response =>{

        dispatch(setOrderEvents(response.data));
        dispatch(setEventNeedUpdate(false));
        
      });
    }else if(customerType == 'business'){
      new BusinessOrder().getEvents({
        customerId: customerId,
        language: language,
        orderId: orderId,
        restUrl: restServerUrl
      }).then(response =>{

        dispatch(setOrderEvents(response.data));
        dispatch(setEventNeedUpdate(false));

      });
    }
  }

  useEffect(() => {

    console.log({isEventNeedUpdate});
    if(isEventNeedUpdate){
      updateEvents();
    }

  },[isEventNeedUpdate])

  return (
    null
  );
};

