import { CallButton } from "@app/components/Call-btn";
import { DetailsButton } from "@app/components/Details-btn";
import { DriverProfile } from "@app/components/Driver-Profile";
import { EventBox } from "@app/components/events-box";
import { Status } from "@app/components/Status";
import { RootState } from "@app/store";
import { OrderStatus, OrderType } from "@app/types/orders";
import { memo } from "react";
import { useSelector } from "react-redux";
import { Footer, FooterWarper } from "./mainBottom.styles";

type MainBottomProps = {
  orderCode: string;
  vehicleTypeIcon?: string;
};

type OndemandSectionBottomProps = {
  orderCode: string;
  vehicleTypeIcon?: string;
};

type PDSectionBottomProps = {
  orderCode: string;
};

export const MainBottom = ({ orderCode, vehicleTypeIcon }: MainBottomProps) => {
  const orderType = useSelector((state: RootState) => state.order.type);

  return (
    <Footer height={orderType === "Ondemand" ? "195px" : "177px"}>
      <Status />
      <FooterWarper>
        {orderType === "Ondemand" ? (
          <OndemandSectionBottom
            orderCode={orderCode}
            vehicleTypeIcon={vehicleTypeIcon}
          />
        ) : (
          <PDSectionBottom orderCode={orderCode} />
        )}
      </FooterWarper>
    </Footer>
  );
};

const OndemandSectionBottom = ({
  orderCode,
  vehicleTypeIcon,
}: OndemandSectionBottomProps) => {
  const driver = useSelector((state: RootState) => state.order.driver);

  return (
    <>
      {driver ? (
        <DriverProfile
          avatarPhoto={driver.avatarPhoto}
          name={driver.name}
          rate={driver.rate}
          vehicleTypeIcon={vehicleTypeIcon}
          vehiclePlate={driver.vehicle.plate}
          vehicleColor={driver.vehicle.color}
          vehicleModel={driver.vehicle.model}
          height="58px"
        />
      ) : null}

      <div
        style={{
          float: "left",
          display: "flex",
          width: "100%",
        }}
      >
        <CallButton phone={driver?.phone} customStyles={{ marginLeft: "0" }} />
        <DetailsButton orderCode={orderCode} />
      </div>
    </>
  );
};

const PDSectionBottom = ({ orderCode }: PDSectionBottomProps) => {
  const events = useSelector(
    (state: RootState) => state.order.events[state.order.events.length - 1]
  );

  return (
    <>
      <EventBox
        title={events.title}
        description={events.description}
        createdAt={events.createdAt}
      />

      <DetailsButton orderCode={orderCode} />
    </>
  );
};



export const MemoMainBottom = memo(MainBottom) 