import {
  OrderTrackResponse,
  IndividualTrackDriverResponse,
  BusinessTrackDriverResponse,
  IndividualEstimatedArrivalResponse,
  BusinessEstimatedArrivalResponse,
  IndividualGetEventsResponse,
  BusinessGetEventsResponse,
} from "@app/types/api";
import { Languages } from "@app/types/multilingual";
import Axios from "axios";
import { LocalData } from "@app/services/localStorage";
import { localStorageConst } from "@app/utils/constants";

type TrackOrderInput = {
  restUrl?: string;
  orderCode: string;
  language: string| null;
};

type TrackDriverInput = {
  restUrl: string;
  orderId: string;
  customerId: string;
  language: Languages;
};

type EstimatedArrivalInput = {
  restUrl: string;
  orderId: string;
  customerId: string;
  language: Languages;
};

type GetEventsInput = {
  restUrl: string;
  orderId: string;
  customerId: string;
  language: Languages;
};

interface OrderAPIs {
  trackOrder(data: TrackOrderInput): Promise<OrderTrackResponse>;
  trackDriver(data: TrackDriverInput): Promise<IndividualTrackDriverResponse | BusinessTrackDriverResponse>;
  estimatedArrival(data: EstimatedArrivalInput): Promise<IndividualEstimatedArrivalResponse | BusinessEstimatedArrivalResponse >;
  getEvents(data: GetEventsInput): Promise<IndividualGetEventsResponse | BusinessGetEventsResponse >;

}

export class IndividualOrder implements OrderAPIs {
  
  private storage = new LocalData();

  public async trackOrder({
    orderCode,
    restUrl,
    language
  }: TrackOrderInput): Promise<OrderTrackResponse> {
    console.log({language});
    
    // const [{ data, loading, error }, refetch] = useAxios<OrderTrackResponse>(`${restUrl? restUrl : ''}/api/v1/customer/individual/order/track/${orderCode}`);
    const response = await Axios.get<OrderTrackResponse>(`${  restUrl ? restUrl : "" }/api/v1/customer/individual/order/track/${orderCode}`, {params: {language: language}} );
    return response.data;
  }

  public async trackDriver({
    orderId,
    customerId,
    restUrl,
    language,
  }: TrackDriverInput): Promise<IndividualTrackDriverResponse> {
    const accessToken = this.storage.get(localStorageConst.accessToken);

    const response = await Axios.request<IndividualTrackDriverResponse>({
      method: "POST",
      url: `${restUrl}/api/v1/customer/individual/order/track-driver`,
      headers: {
        "Accept-Language": language,
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        orderId: orderId,
        customerId: customerId,
      },
    });
    return response.data;
  }

  public async estimatedArrival({
    orderId,
    customerId,
    restUrl,
    language,
  }: EstimatedArrivalInput): Promise<IndividualEstimatedArrivalResponse> {
    const accessToken = this.storage.get(localStorageConst.accessToken);
    // const accessToken = new LocalData().get(localStorageConst.accessToken)

    const response = await Axios.request<IndividualEstimatedArrivalResponse>({
      method: "POST",
      url: `${restUrl}/api/v1/customer/individual/order/ondemand/estimated-arrival`,
      headers: {
        "Accept-Language": language,
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        orderId: orderId,
        customerId: customerId,
      },
    });
    return response.data;
  }

  public async getEvents({orderId, customerId,   restUrl,    language,}: GetEventsInput): Promise<IndividualGetEventsResponse> {
    
    const accessToken = this.storage.get(localStorageConst.accessToken);

    const response = await Axios.request<IndividualGetEventsResponse>({
      method: "GET",
      url: `${restUrl}/api/v1/customer/individual/order/event`,
      headers: {
        "Accept-Language": language,
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        orderId: orderId,
        customerId: customerId,
      },
    });
    return response.data;

  }
}

// =================== Business =================================================

export class BusinessOrder implements OrderAPIs {
  private storage = new LocalData();

  trackOrder(data: TrackOrderInput): Promise<OrderTrackResponse> {
    throw new Error("Method not implemented.");
  }
  public async trackDriver({
    orderId,
    customerId,
    restUrl,
    language,
  }: TrackDriverInput): Promise<BusinessTrackDriverResponse> {
    const accessToken = this.storage.get(localStorageConst.accessToken);

    const response = await Axios.request<BusinessTrackDriverResponse>({
      method: "POST",
      url: `${restUrl}/api/v1/customer/business/order/track-driver`,
      headers: {
        "Accept-Language": language,
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        orderId: orderId,
        customerId: customerId,
      },
    });
    return response.data;
  }
  public async estimatedArrival({
    orderId,
    customerId,
    restUrl,
    language,
  }: EstimatedArrivalInput): Promise<BusinessEstimatedArrivalResponse> {
    const accessToken = this.storage.get(localStorageConst.accessToken);

    const response = await Axios.request<BusinessEstimatedArrivalResponse>({
      method: "POST",
      url: `${restUrl}/api/v1/customer/business/order/ondemand/estimated-arrival`,
      headers: {
        "Accept-Language": language,
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        orderId: orderId,
        customerId: customerId,
      },
    });
    return response.data;
  }

  public async getEvents({orderId, customerId,   restUrl,    language,}: GetEventsInput): Promise<BusinessGetEventsResponse> {
    
    const accessToken = this.storage.get(localStorageConst.accessToken);

    const response = await Axios.request<BusinessGetEventsResponse>({
      method: "GET",
      url: `${restUrl}/api/v1/customer/business/order/event`,
      headers: {
        "Accept-Language": language,
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        orderId: orderId,
        customerId: customerId,
      },
    });
    return response.data;

  }
}

