import { ColorsFallback } from '@app/utils/constants';
import styled, { keyframes } from 'styled-components';


const wave = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
`

export const BallsWarper = styled.div`
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & :nth-child(1) {
      animation-delay: -0.4s;
    }
  & :nth-child(2) {
      animation-delay: -0.2s;
    }
`;

interface IBalls  {
  color?: string,
}

export const Balls = styled.div<IBalls>`
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: ${props => props.color || ColorsFallback.secondaryDarkColor };
  transform: translateY(-100%);
  animation: ${wave} 0.8s ease-in-out alternate infinite;
`;

