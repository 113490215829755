import styled from 'styled-components';
import { ColorsFallback } from '@app/utils/constants'

interface IParagraph  {
  color?: string,
}


export const StyledParagraph = styled.p<IParagraph>`
  font-family: SFProRounded;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${props=> props.color || ColorsFallback.secondaryColor};
  width: 100%;
  float: left;
  margin: 16px 0 0 0;
  padding: 15px 0 15px 0;
  outline: none;
  border-radius: 8px;
  border: solid 1px ${props=> props.color || ColorsFallback.secondaryColor};
  cursor: pointer;
  flex: 1;
  align-self: baseline;

`;
