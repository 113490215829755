// import { getSetting } from '@app/utils/project';
import {BallsWarper , Balls} from './loading.styles';
import { RootState } from "@app/store";
import { useSelector } from "react-redux";

export const Loader = () => {

  const color = useSelector((state: RootState) => state.setting.colors.secondaryColor);

  return (
    <BallsWarper>
      <Balls color={color}/>
      <Balls color={color}/>
      <Balls color={color}/>
    </BallsWarper>
  );
};
