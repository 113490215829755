import styled from "styled-components";

export const StyledHeader = styled.div`
  width: 100%;
  height: 54px;
  margin: 0;
  padding: 0;
  flex-grow: 0;
  background-color: #fff;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: 0 0 2px 0 rgba(128, 128, 128, 0.3);
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  
`;

export const StyledLogoImage = styled.img`
  width: auto;
  height: 40px;
  flex-grow: 0;
  float: left;
  object-fit: contain;
  margin-left: 16px;
`;

export const StyledSpan = styled.span`
  width: auto;
  height: auto;
  font-family: SFProRounded;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  float: right;
  flex-grow: 0;
  font-size: 16px;
  color: #242424;
  margin-right: 16px;
`;
