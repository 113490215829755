

// MM/dd/yyyy, hh:mm tt using Intl.DateTimeFormat
export const formatDateFull = (unixNumber: number , language: string = "en"): string =>{

    const date = new Date(unixNumber)
    let p = new Intl.DateTimeFormat(language,{
      year:'numeric',
      month:'2-digit',
      day:'2-digit',
      hour:'2-digit',
      minute:'2-digit',
      hour12: false
    }).formatToParts(date).reduce((acc:any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});
    
    return `${p.month}/${p.day}/${p.year}, ${p.hour}:${p.minute} ${p.dayPeriod}`; 
  }
  
  // hh:mm using Intl.DateTimeFormat
export const formatDateTime = (unixNumber: number , language: string = "en"): string =>{

    const date = new Date(unixNumber)
    let p = new Intl.DateTimeFormat(language,{
      year:'numeric',
      month:'2-digit',
      day:'2-digit',
      hour:'2-digit',
      minute:'2-digit',
      hour12: false
    }).formatToParts(date).reduce((acc:any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});
    
    return `${p.hour}:${p.minute}`; 
  }
  