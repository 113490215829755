import { Marker } from 'react-leaflet';
import {Icon} from 'leaflet';
import IcPinDriver from '@app/assets/icon/ic_pin_driver_free.svg'

type OsmDriverPinProps = {
  position: {
    lat: number;
    lng: number;
  };
};

const iconDropOff = new Icon({
  iconUrl: IcPinDriver,
  iconRetinaUrl: IcPinDriver,
  iconSize: [60,60]
});


export const OsmDriverPin = ({ position }: OsmDriverPinProps) => {

  return (
    <Marker position={position} icon={iconDropOff} />
  )
};



