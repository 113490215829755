import { Marker } from 'react-leaflet';
import {Icon} from 'leaflet';
import IcDropoffPin from "@app/assets/icon/ic_dropoff_pin.svg";

type OsmDropOffPinProps = {
  position: {
    lat: number;
    lng: number;
  };
};

const iconDropOff = new Icon({
  iconUrl: IcDropoffPin,
  iconRetinaUrl: IcDropoffPin,
  iconSize: [60,60]
});


export const OsmDropOffPin = ({ position }: OsmDropOffPinProps) => {

  return (
    <Marker position={position} icon={iconDropOff} />
  )
};



