import { configureStore } from '@reduxjs/toolkit'
import settingReducer from './setting.slice'
import customerReducer from './customer.slice'
import orderReducer from './order.slice'
import markerReducer from './marker.slice'
import serviceReducer from './service.slice'
import {environment} from '@app/config/environment'

console.log('environment  ' , environment);

export const store = configureStore({
  reducer: {
    setting: settingReducer,
    customer: customerReducer,
    order: orderReducer,
    marker: markerReducer,
    service: serviceReducer
  },
  devTools: environment.isDevelopment
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch