import styled from 'styled-components';



export const StyledLink = styled.a`
  height: auto;
  font-family: SFProRounded;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #009b62;
  width: calc(100% - 32px);
  padding: 15px 0;
  float: left;
  border-radius: 8px;
  border: solid 0.7px rgba(0, 155, 98, 0.3);
  background-color: rgba(0, 155, 98, 0.04);
  margin: 16px 16px 12px;
  text-decoration: none;
  flex: 1;
`;
